import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import slug from "slugify";
import Header from "../header/Header"
import Sidebar from "../sidebar/Sidebar" 

export default function ViewUser() {
  const [allWebsite,setAllWebsite] = useState([]);
  const [loading,setLoading] = useState(false)
  const [percentage,setPercentage] = useState(0);
  const [chooseFile,setChooseFile] = useState("");
  const [websiteId,setWebsiteId] = useState("");

  const uploadProduct=async()=>{
  const formData = new FormData();
  formData.append("file",chooseFile[0]);
  formData.append("websiteId",websiteId);
  await axios.post("http://api.themini.site/api/uploadexl",formData,{
    headers:{"Content-Type":"Multipart/form-data"},
    onUploadProgress:(data)=>{
      setPercentage(Math.round((100*data.loaded)/data.total))
    }
  }).then((res)=>{
      console.log(res)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

	const getCustomers=async()=>{
    setLoading(true)
		const res = await axios.post("http://api.themini.site/api/getwebsiteoption")
		if(res.status===200){ 
      setAllWebsite(res.data);
      setLoading(false)
		}
	}

  useEffect(()=>{
    getCustomers();
  },[])

	const preview=(url)=>{
		return(<div className="flex-row justify-center items-center">
			 	<svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-template w-full"
  width={100}
  height={100}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="currentColor"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
  <path d="M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
  <path d="M14 12l6 0" />
  <path d="M14 16l6 0" />
  <path d="M14 20l6 0" />
</svg>
<p className="flex justify-center">
<Link to={"/tmp/"+url+"/"} target="_blank" className="bg-blue-500 text-white py-2 px-5 text-sm rounded-md shadow-md">
Click to Show Preview
</Link>
</p>
    			</div>)
	}
  
  const checked = <svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-photo-check"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="#ffffff"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
  <path d="M9 12l2 2l4 -4" />
</svg>

	return (
		<div>
		<Header/>
		<div className="flex w-9/12 mx-auto space-x-2 h-96">
		<div className="w-2/12 h-96 bg-gray-100 py-2">
		<Sidebar/>
		</div>
		<div className="w-10/12 p-2">
		<div className="w-full bg-white border border-[#e9ebee]  rounded-md">
		<div className="flex py-4 px-4  justify-between items-center w-full bg-[#333]">
		<h1 className=" text-md  text-white shadow-sm">Add Product On Websites</h1>
		 
		
		</div>

    <div className="flex-col space-y-2 px-4 py-5">
    <div>
    <p className="py-1 text-sm text-gray-500">Choose website</p>
      <select className="w-4/12 p-1" onChange={(e)=>setWebsiteId(e.target.value)}>
        <option>All</option>
        {allWebsite?.map(dt=>{return(<option value={dt?._id}>{dt?.websiteName}</option>)})}
      </select>
    </div>

    <div>
    <p className="py-1 text-sm text-gray-500">Choose Excel products</p>
      <input type="file" name="file" onChange={(e)=>setChooseFile(e.target.files)}/>
      </div>
    
    <div> 
    <button className="text-sm rounded-md bg-green-600 text-white px-5 py-1" onClick={(e)=>uploadProduct()}>Upload</button>
      </div>
    </div>

  
  

		 
			</div>
		</div>
			 
		</div>
		</div>
	)
}