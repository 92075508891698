import React,{useState} from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from "axios";
import slug from "slugify";
import Header from "../header/Header"
import Sidebar from "../sidebar/Sidebar" 

export default function Template() {
	const {slug} = useParams();
	const navigate = useNavigate();
	const [chooseTemplate,setChooseTemplate] = useState("")
	const [name,setName] = useState("")
	const [email,setEmail] = useState("")
	const [natureOfBusiness,setNatureOfBusiness] = useState("")
	const [phoneNumber,setPhoneNumber] = useState("")
	const [cityName,setCityName] = useState("")
	const [address,setAddress] = useState("")
	const [description,setDescription] = useState("")

	const create=async()=>{
		const data = {chooseTemplate,id:slug}
		console.log(data)
		const res = await axios.post("http://api.themini.site/api/usertemplate",data)
		if(res.status===200){
			alert("Template successfully created");
			navigate(`/templateinfo/${slug}`)
		}
	}

	const preview=(url)=>{
		return(<div className="flex-row justify-center items-center">
			 	<svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-template w-full"
  width={100}
  height={100}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="currentColor"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
  <path d="M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
  <path d="M14 12l6 0" />
  <path d="M14 16l6 0" />
  <path d="M14 20l6 0" />
</svg>
<p className="flex justify-center">
<Link to={"/tmp/"+url+"/"} target="_blank" className="bg-blue-500 text-white py-2 px-5 text-sm rounded-md shadow-md">
Click to Show Preview
</Link>
</p>
    			</div>)
	}

	const previewTwo=(url)=>{
		return(<div className="flex-row justify-center items-center">
			 	<svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-template w-full"
  width={100}
  height={100}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="currentColor"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
  <path d="M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
  <path d="M14 12l6 0" />
  <path d="M14 16l6 0" />
  <path d="M14 20l6 0" />
</svg>
<p className="flex justify-center">
<Link to={"/tmp/"+url+"/"} target="_blank" className="bg-blue-500 text-white py-2 px-5 text-sm rounded-md shadow-md">
Click to Show Preview
</Link>
</p>
    			</div>)
	}
  
  const checked = <svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-photo-check"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="#ffffff"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
  <path d="M9 12l2 2l4 -4" />
</svg>

	return (
		<div>
		<Header/>
		<div className="flex w-9/12 mx-auto space-x-2 h-96">
		<div className="w-2/12 h-96 bg-gray-100 py-2">
		<Sidebar/>
		</div>
		<div className="w-10/12 p-2">
		<div className="w-full bg-white border border-[#e9ebee]  rounded-md">
		<h1 className=" py-4 px-4 text-md bg-[#333] text-white shadow-sm border-b border-gray-200">Add New Template for customer Details</h1>
		<div>
		<div className="flex space-x-2">
			<div className="flex-col py-2 space-y-2 px-4 w-6/12 border-r border-gray-100">
			
			<p className="text-md py-1">Choose any one Template</p>
			<div className="flex space-x-2">
				<label className="h-32 w-32 text-sm rounded-md bg-sky-400 flex justify-center items-center">
						<div className="flex flex-col justify-center items-center space-y-2">
						<p className="text-gray-800">Template 1</p>
						<div>{chooseTemplate==="one"?checked:""}</div>
						</div>
					<input type="radio" name="template" value="one" className="hidden" onChange={(e)=>setChooseTemplate(e.target.value)}/>
				</label>
				<label className="h-32 w-32 text-sm rounded-md bg-red-400 flex justify-center items-center">
						<div className="flex flex-col justify-center items-center space-y-2">
						<p className="text-gray-800">Template 2</p>
						<div>{chooseTemplate==="two"?checked:""}</div>
						</div>
					<input type="radio" name="template" value="two" className="hidden" onChange={(e)=>setChooseTemplate(e.target.value)}/>
				</label>
				<label className="h-32 w-32 text-sm rounded-md bg-yellow-400 flex justify-center items-center">
						<div className="flex flex-col justify-center items-center space-y-2">
						<p className="text-gray-800">Template 3</p>
						<div>{chooseTemplate==="three"?checked:""}</div>
						</div>
					<input type="radio" name="template" value="three" className="hidden" onChange={(e)=>setChooseTemplate(e.target.value)}/>
				</label>
			</div>
			<div className="flex space-x-2">
				<label className="h-32 w-32 text-sm rounded-md bg-pink-400 flex justify-center items-center">
						<div className="flex flex-col justify-center items-center space-y-2">
						<p className="text-gray-800">Template 4</p>
						<div>{chooseTemplate==="fourth"?checked:""}</div>
						</div>
					<input type="radio" name="template" value="fourth" className="hidden" onChange={(e)=>setChooseTemplate(e.target.value)}/>
				</label>
				<label className="h-32 w-32 text-sm rounded-md bg-purple-400 flex justify-center items-center">
						<div className="flex flex-col justify-center items-center space-y-2">
						<p className="text-gray-800">Template 5</p>
						<div>{chooseTemplate==="fifth"?checked:""}</div>
						</div>
					<input type="radio" name="template" value="fifth" className="hidden" onChange={(e)=>setChooseTemplate(e.target.value)}/>
				</label> 
			</div>
			
			 
			{/*<div>
			<p className="text-md py-1">Choose Banner</p>
			<input type="file" placeholder="Customer name" className="border border-gray-300 rounded-md py-1 px-2 w-full"/>
			</div>*/}
			<div>
			<button  
					className="bg-green-600 text-white border border-gray-300 rounded-md py-1 px-2 w-full" 
					onClick={(e)=>create()}>
					Next
			</button> 
			{/*<Link to="/templateinfo/123">Next</Link>*/}
			</div>
			
			</div>
			<div className="flex flex-col w-full justify-center items-center">
			{/*<svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-browser-check"
  width={100}
  height={100}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="gray"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
  <path d="M4 8h16" />
  <path d="M8 4v4" />
  <path d="M9.5 14.5l1.5 1.5l3 -3" />
</svg>

				<h1>Web Preview</h1>*/}
				{chooseTemplate==="one"?preview("one"):""}
				{chooseTemplate==="two"?previewTwo("two"):""}
				{chooseTemplate==="three"?"Template One Choosed":""}
				{chooseTemplate==="fourth"?"Template One Choosed":""}
				{chooseTemplate==="fifth"?"Template One Choosed":""}
			</div>
			</div>
		</div>
			
		</div>
		</div>
		</div>
		</div>
	)
}