import React from "react"
import {Routes,Route} from "react-router-dom";
import Login from "./component/login/Login"
import Home from "./component/home/Home"
import CreateUser from "./component/users/CreateUser"
import Customer from "./component/customer/Customer"
import Template from "./component/content/Template"
import TemplateInfo from "./component/content/TemplateInfo"
import ViewUser from "./component/content/ViewUser"
import AddProduct from "./component/product/AddProduct"
import FirstTemplate from "./component/template/FirstTemplate"
import SecondTemplate from "./component/template/SecondTemplate"
import ExportExcel from "./ExcelExport"
import Demo from "./component/template/Demo"

import Dynamic from './Dynamic'

function App() {
  return (
    <div className="App">
   
        <Routes>
          <Route path="/" element={<Login/>}></Route>
          <Route path="/home" element={<Home/>}></Route>
          <Route path="/adduser" element={<CreateUser/>}></Route>
          <Route path="/:slug" element={<Customer/>}></Route>
          <Route path="/template/:slug" element={<Template/>}></Route>
          <Route path="/templateinfo/:slug" element={<TemplateInfo/>}></Route>
          <Route path="/viewuser" element={<ViewUser/>}></Route>
          <Route path="/products" element={<AddProduct/>}></Route>

          <Route path="/tmp/one" element={<FirstTemplate/>}></Route>
          <Route path="/tmp/two" element={<SecondTemplate/>}></Route>
          <Route path="/export" element={<ExportExcel/>}></Route>
          <Route path="/d" element={<Demo/>}></Route>
        </Routes>
     
    </div>
  );
}

export default App;
