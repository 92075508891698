import React, { useState } from 'react';

function MyComponent() {
  const firstArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const secondArray = [0, 1, 2, 3]; // New values for the second array

  const [firstArrayIndex, setFirstArrayIndex] = useState(0);
  const [secondArrayIndex, setSecondArrayIndex] = useState(0);

  const processFirstArray = () => {
    // Check if there are more elements in the first array
    if (firstArrayIndex < firstArray.length) {
      // Process the element from the first array
      const element = firstArray[firstArrayIndex];
      console.log(`Processing element from first array: ${element}`);

      // Update the index for the first array
      setFirstArrayIndex(firstArrayIndex + 1);

      // Check if 5 elements from the first array have been processed
      if ((firstArrayIndex + 1) % 5 === 0) {
        // Update the index for the second array
        setSecondArrayIndex((secondArrayIndex + 1) % secondArray.length); // Wrap around if needed
        console.log(`Updating second array index to ${secondArrayIndex}`);
      }
    }
  };

  // Create an array to hold the divs for every 5 elements
  const divsForFiveElements = [];
  for (let i = 0; i < firstArray.length; i += 5) {
    const slice = firstArray.slice(i, i + 5);
    const divs = slice.map((element, index) => (
      <div key={index}>Element {element}</div>
    ));
    divsForFiveElements.push(
      <div key={i} className="group">
        {divs}
      </div>
    );
  }

  return (
    <div>
      <button onClick={processFirstArray}>Process First Array</button>
      <p>Second Array Index: {secondArrayIndex}</p>
      {divsForFiveElements}
    </div>
  );
}

export default MyComponent;
