import {LOGIN_STATUS} from '../action/Status';

const initialData = {
	token:null,
	user:[],
	authenticate:false,
	authenticating:false, 
	loading:false,
	error:""

};

export default (state=initialData,action)=>{
	 
	 switch(action.type){


	 	case LOGIN_STATUS.LOGIN_REQUEST:
	 	    state={
	 	    	...state,
	 	    	authenticating:true,
	 	    	loading:true
	 	    }
	 	break;
	 	case LOGIN_STATUS.LOGIN_SUCCESS:
	 	     state={
	 	     	...state, 
	 	     	user:action.payload.user,
	 	     	token:action.payload.token,
	 	     	loading:false,
	 	     	authenticating:false,
	 	     	authenticate:true,
	 	     	error:""
			}
	 	break; 
	 	case LOGIN_STATUS.LOGIN_FAILED:
	 	     state={
	 	     	...state,  
	 	     	loading:false,
	 	     	error:action.payload.err

	 	     }
	 	break;
 
	}
	 return state;
}