import React,{useState} from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from "axios";
import slugify from "slugify";
import Header from "../header/Header"
import Sidebar from "../sidebar/Sidebar" 

export default function Template() {
	const {slug} = useParams();
	const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("tab1");
   const handleTab1 = () => {
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    setActiveTab("tab2");
  };
	 
	const [logo,setLogo] = useState("")
	const [websiteName,setWebsiteName] = useState("")
	const [phoneNumber,setPhoneNumber] = useState("")
	const [email,setEmail] = useState("")
	const [address,setAddress] = useState("")
	const [description,setDescription] = useState("")

	// const create=async()=>{
	// 	const data = {logo,websiteName,phoneNumber,email,address,userId:slug}
	// 	const res = await axios.post("http://api.themini.site/api/userwebsite",data)
	// 	if(res.status===200){
	// 		alert("Create successfull");
	// 	}
	// }

    const [formFields, setFormFields] = useState([
    { headerName: '' },
  ])

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  }

  const submit = (e) => {
    e.preventDefault();
    console.log(formFields)
  }

  const addFields = () => {
    let object = {
      headerName: '' 
    }

    setFormFields([...formFields, object])
  }

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1)
    setFormFields(data)
  }





    const [headings, setHeadings] = useState([{ title: "", inputs: [{ name: "", price: "" }] }]);
    const [banners, setBanners] = useState([{ bannerData: [{ bannerImg: "", bannerLink: "" }] }]);

  const handleHeadingChange = (index, key, value) => {
    const updatedHeadings = [...headings];
    updatedHeadings[index][key] = value;
    setHeadings(updatedHeadings);
  };

  const handleNamePriceChange = (headingIndex, inputIndex, key, value) => {
    const updatedHeadings = [...headings];
    updatedHeadings[headingIndex].inputs[inputIndex][key] = value;
    setHeadings(updatedHeadings);
  };

  const handleAddHeading = () => {
    const updatedHeadings = [...headings, { title: "", inputs: [{ name: "", price: "" }] }];
    setHeadings(updatedHeadings);
  };

  const handleAddInput = (headingIndex) => {
    const updatedHeadings = [...headings];
    updatedHeadings[headingIndex].inputs.push({ name: "", price: "" });
    setHeadings(updatedHeadings);
  };

  const handleRemoveInput = (headingIndex, inputIndex) => {
    const updatedHeadings = [...headings];
    updatedHeadings[headingIndex].inputs.splice(inputIndex, 1);
    setHeadings(updatedHeadings);
  };

  const handleRemoveHeading = (headingIndex) => {
    const updatedHeadings = [...headings];
    updatedHeadings.splice(headingIndex, 1);
    setHeadings(updatedHeadings);
  };

  const extractDataAsJSON = () => {
    const data = headings.map((heading) => {
      return {
        title: heading.title,
        inputs: heading.inputs,
      };
    });

    return data
  };

// Banners Data...........

 const [bannerFields, setBannerFields] = useState([{ file: null, link: '' }]);

  const handleFileInputChange = (index, e) => {
    const updatedInputs = [...bannerFields];
    updatedInputs[index].file = e.target.files[0];
    setBannerFields(updatedInputs);
  };

  const handleLinkInputChange = (index, e) => {
    const updatedInputs = [...bannerFields];
    updatedInputs[index].link = e.target.value;
    setBannerFields(updatedInputs);
  };

  const addInput = () => {
    setBannerFields([...bannerFields, { file: null, link: '' }]);
  };

  const removeInput = (index) => {
    const updatedInputs = [...bannerFields];
    updatedInputs.splice(index, 1);
    setBannerFields(updatedInputs);
  };

  const handleUpload =  async (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    bannerFields.forEach((input, index) => {
      formData.append(`file`, input.file);
      formData.append(`links${index}`, input.link);
    });
 
      console.log("formData",formData)
      try {
      const response = await axios.post('http://api.themini.site/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

// Banner data END......









  const handleExtractData = async(e) => {
    e.preventDefault();
  const jsonData = extractDataAsJSON();
  console.log(jsonData);
  var productData =[]

    const formData = new FormData();
    const formLogoData = new FormData();
    
    bannerFields.forEach((input, index) => {
      formData.append(`file`,input.file);
      formData.append(`links${index}`,input.link);
    });

    headings.forEach((input, index) => {
      productData.push(input)
    });
    const productArr = JSON.stringify(productData)
    const headerArr = JSON.stringify(formFields)
      formLogoData.append(`logo`,logo)
      formData.append(`websiteName`,websiteName)
      formData.append(`phoneNumber`,phoneNumber)
      formData.append(`email`,email)
      formData.append(`address`,address)
      formData.append(`userId`,slug)
      formLogoData.append(`userId`,slug)
      formData.append(`product`,productArr)
      formData.append(`headerDetails`,headerArr)
 
   // const res = await axios.post("http://api.themini.site/api/userwebsite",data)
   const res = await axios.post("http://api.themini.site/api/upload",formData,{
   // const res = await axios.post("http://api.themini.site/api/upload",formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

   const resLogo = await axios.post("http://api.themini.site/api/uploadlogo",formLogoData,{
   // const res = await axios.post("http://api.themini.site/api/upload",formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
   if(res.status===200){
     alert("Create successfull");
   }
};


 






 
  
  const checked = <svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-photo-check"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="#ffffff"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
  <path d="M9 12l2 2l4 -4" />
</svg>

	const trashBtn = <svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-trash"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="#999"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 7l16 0" />
  <path d="M10 11l0 6" />
  <path d="M14 11l0 6" />
  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
</svg>


	return (
				<div>
		<Header/>
		<div className="flex w-9/12 mx-auto space-x-2 h-96">
		<div className="w-2/12 h-96 bg-gray-100 py-2">
		<Sidebar/>
		</div>
		<div className="w-10/12 p-2">
		<div className="w-full  ">
		<h1 className="py-4 px-4 text-md bg-[#333] text-white shadow-sm border-b border-gray-200">Website Details</h1>
		<div>
		<div className="flex space-x-2">
			<div className="flex-col py-2 space-y-2  w-full  border-r border-gray-100">
			<div className="bg-white shadow-sm">
			<div className="flex justify-between  bg-[#ccc] items-center w-full pr-2 py-1">
			<p className="text-lg py-1 px-2 text-[#333] text-sm">Header Details</p>
			 <button onClick={addFields} className="bg-blue-500 text-xs text-white drop-shadow-sm text-white px-2 py-1 rounded-sm">+ Add More</button>
			</div>
			<div className="flex justify-start space-x-2">
			<div className="px-2">
			<p className="text-md py-1 text-sm text-gray-500">Logo</p>
			<input type="file" className="border border-gray-300 rounded-md py-1 px-2 w-full" onChange={(e)=>setLogo(e.target.files[0])}/>
			 
			</div>
			<div>
			<p className="text-md py-1 text-sm text-gray-500">Website name</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full" onChange={(e)=>setWebsiteName(e.target.value)}/>
			<p className="text-xs py-2">Web URL  https://www.example.com/{websiteName?slugify(websiteName):""}</p>
			</div>

			<div>
      {formFields.map((form, index) => {
          return (
            <div key={index} className="flex-col">
            <p className="text-md py-1 text-sm text-gray-500">Other details</p>
            <div className="flex space-x-2">
              <input
                name='headerName'
                className="border border-gray-300 rounded-md py-1 px-2 w-full"
                onChange={event => handleFormChange(event, index)}
                value={form.headerName}
              /> 
              <button onClick={() => removeFields(index)}>{trashBtn}</button>
            </div>
            </div>
          )
        })}
	    </div>

			</div>
		 
			</div>

 <div className="flex justify-center w-full py-2 space-x-2">
        <button onClick={handleTab1} 
        className={activeTab === "tab1" ? "active bg-red-600 text-white py-2 px-5" : "bg-gray-300 text-gray-500 py-2 px-5"} 
        >
        All product upload
        </button>
        <button 
        onClick={handleTab2} 
        className={activeTab === "tab2" ? "active bg-red-600 text-white py-2 px-5" : "bg-gray-300 text-gray-500 py-2 px-5"} 
        >
        Only Card upload
        </button>
      </div>

 {activeTab === "tab1" ?
        <div>
			<div className="bg-white py-2 shadow-sm">
			<div className="flex justify-between  bg-[#d7d7d7] items-center w-full pr-2 py-1">
			<p className="text-lg text-[#333] py-1 px-2 text-sm">Add Products</p>
			<button onClick={handleAddHeading} className="bg-blue-500 text-white text-xs drop-shadow-sm px-2 py-1 rounded-sm">+ Add more Heading</button>
			{/*<button onClick={addProductFields} className="bg-[#f5f6f8] text-[#333] text-xs drop-shadow-sm px-2 py-1 rounded-sm"> + Add More </button>*/}
			</div>
			<div className="flex-col px-2 justify-start  py-2">
				 {headings.map((heading, headingIndex) => (
        <div key={headingIndex} className="bg-white p-2">
        <div className=" flex justify-between items-center items-center border-l-4 pb-2  border-black">
             <div className="flex-col w-6/12 ml-2">
             <p className="text-md py-1 text-sm text-gray-700">Heading Name</p>
          <input
            type="text"
            placeholder="Heading"
            className="border border-gray-300 rounded-md py-1 px-2 w-full"
            value={heading.title}
            onChange={(e) =>
              handleHeadingChange(headingIndex, "title", e.target.value)
            }
          />
          </div>
          <div className="flex space-x-2 mt-6">
          <button onClick={() => handleRemoveHeading(headingIndex)}>
            {trashBtn}
          </button>
          </div>
          </div>
          <div className="border-b border-gray-300 pb-2">
          <div className="flex justify-center items-center"><svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-fold-down"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="currentColor"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M12 11v8l3 -3m-6 0l3 3" />
  <path d="M9 7l1 0" />
  <path d="M14 7l1 0" />
  <path d="M19 7l1 0" />
  <path d="M4 7l1 0" />
</svg></div>
          {heading.inputs.map((input, inputIndex) => (
            <div key={inputIndex}>
             <div className="flex justify-start space-x-2 items-center">

            <div className="flex-col ">
             <p className="text-md py-1 text-sm text-gray-500">Product Name</p>
              <input
                type="text"
                placeholder="Name"
                className="border border-gray-300 rounded-md py-1 px-2 w-full"
                value={input.name}
                onChange={(e) =>
                  handleNamePriceChange(
                    headingIndex,
                    inputIndex,
                    "name",
                    e.target.value
                  )
                }
              />
              </div>
              <div>
            <p className="text-md py-1 text-sm text-gray-500">Product Price</p>
              <input
                type="text"
                placeholder="Price"
                className="border border-gray-300 rounded-md py-1 px-2 w-full"
                value={input.price}
                onChange={(e) =>
                  handleNamePriceChange(
                    headingIndex,
                    inputIndex,
                    "price",
                    e.target.value
                  )
                }
              />

              </div>
              <button
                onClick={() => handleRemoveInput(headingIndex, inputIndex)}
                className="pt-6"
              >
                {trashBtn}
              </button>
              </div>
            </div>
          ))}
          <button 
            onClick={() => handleAddInput(headingIndex)}
            className="bg-yellow-600 text-sm px-2 text-white rounded-md py-1 mt-5"
            >
            + Add more inputs
          </button>
        </div>
        </div>
      ))}
					
			</div>
			</div>


      <div className="bg-white py-2 shadow-sm">
      <div className="flex justify-between  bg-[#d7d7d7] items-center w-full pr-2 py-1">
      <p className="text-lg text-[#333] py-1 px-2 text-sm">Add Banners</p>
      {/*<button onClick={handleAddHeading} className="bg-blue-500 text-white text-xs drop-shadow-sm px-2 py-1 rounded-sm">+ Add more Heading</button>*/}
      {/*<button onClick={addProductFields} className="bg-[#f5f6f8] text-[#333] text-xs drop-shadow-sm px-2 py-1 rounded-sm"> + Add More </button>*/}
      </div>
      <div className="flex-col px-2 justify-start  py-2">
        <div>
        {bannerFields.map((input, index) => (
          <div key={index}>
          <div className="flex justify-start space-x-2 items-center">

            <div className="">
             <p className="text-md py-1 text-sm text-gray-500">Banners Img</p>
            <input
              type="file"
              onChange={(e) => handleFileInputChange(index, e)}
              multiple
            />
            </div>

             <div className="flex-col ">
             <p className="text-md py-1 text-sm text-gray-500">Banners Link</p>
            <input
              type="text"
              placeholder="Enter File Link"
              className="border border-gray-300 rounded-md py-1 px-2 w-full"
              value={input.link}
              onChange={(e) => handleLinkInputChange(index, e)}
            />

          </div>
            <button 
              onClick={() => removeInput(index)}
              className="pt-6"
              >{trashBtn}</button>
          </div>
          </div>
        ))}
        <button 
          onClick={addInput}
          className="bg-yellow-600 text-sm px-2 text-white rounded-md py-1 mt-5">
          + Add more banners
        </button>
      </div> 
          
      </div>
      </div>
      </div>:""}








			<div className="bg-white py-2 shadow-sm">
			<div className="flex justify-between  bg-[#d7d7d7] items-center w-full pr-2 py-1">
			<p className="text-lg text-[#333] py-1 px-2 text-sm">Footer Details</p>
			</div>
			<div className="flex px-2 justify-start space-x-2">
			<div>
			<p className="text-md py-1 text-sm text-gray-500">Phone number</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full"  onChange={(e)=>setPhoneNumber(e.target.value)}/> 
			</div>
			<div>
			<p className="text-md py-1 text-sm text-gray-500">Email address</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full"  onChange={(e)=>setEmail(e.target.value)}/> 
			</div>

			</div>

			<div className="px-2">
			<p className="text-md py-1">Address Details</p>
			<textarea type="text" className="border border-gray-300 h-32 rounded-md py-1 px-2 w-full" onChange={(e)=>setAddress(e.target.value)}/> 
			</div>
			</div>

			 
{/*
			<div>
			<p className="text-md py-1">Page description</p>
			<textarea className="border border-gray-300 rounded-md py-1 px-2 h-32 w-full" onChange={(e)=>setDescription(e.target.value)}/>
			</div>*/}
			{/*<div>
			<p className="text-md py-1">Choose Banner</p>
			<input type="file" placeholder="Customer name" className="border border-gray-300 rounded-md py-1 px-2 w-full"/>
			</div>*/}
		 
			
			
			
			 
			{/*<div>
			<p className="text-md py-1">Choose Banner</p>
			<input type="file" placeholder="Customer name" className="border border-gray-300 rounded-md py-1 px-2 w-full"/>
			</div>*/}
			<div className="w-full flex justify-end">
			<button  
					className="bg-green-600 text-white border border-gray-300 rounded-md py-1 px-2 w-2/12" 
					onClick={handleExtractData}
          >
					Next
			</button>
			</div>
			
			</div>
			 
			</div>
		</div>
			
		</div>
		</div>
		</div>
		</div>
	)
}