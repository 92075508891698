import React, {useEffect,useState,useRef} from 'react';
import axios from "axios";
import { JsonToExcel } from "react-json-to-excel";
import Header from "./component/header/Header"
import Sidebar from "./component/sidebar/Sidebar" 

const Test = () =>  {
    const tableRef = useRef(null);
    const [allUsers,setAllUsers] =useState([])
    const getCustomers=async()=>{ 
		// const res = await axios.post("http://api.themini.site/api/getcustomer")
    const res = await axios.post("http://api.themini.site/api/getcustomer")
		if(res.status===200){ 
      setAllUsers(res.data);
     
		}
	}
useEffect(()=>{
	getCustomers();
},)

        return (
            <div>
            	<Header/>
		<div className="flex w-9/12 mx-auto space-x-2 h-96">
		<div className="w-2/12 h-96 bg-gray-100 py-2">
		<Sidebar/>
		</div>
		<div className="flex flex-col justify-center items-center w-10/12 p-2">
		<h1 className="text-xl py-5">Export users details</h1>
              <JsonToExcel
        title="Download as Excel"
        data={allUsers}
        fileName="sample-file"
        btnClassName="custom-classname"
      />

            </div>
            </div>
            </div>
        );
    }


export default Test