import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import MenuItem from './Menu';

  export default function Sidebar() {
  	const addUserIcon=(<svg
  				xmlns="http://www.w3.org/2000/svg"
  				className="icon icon-tabler icon-tabler-pencil-plus"
  				width={16}
  				height={16}
  				viewBox="0 0 24 24"
  				strokeWidth={2}
  				stroke="currentColor"
  				fill="none"
  				strokeLinecap="round"
  				strokeLinejoin="round"
				>
				   <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
  <path d="M16 19h6" />
  <path d="M19 16v6" />
  <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
				</svg>)

	const addTemplateIcon=(<svg
  				xmlns="http://www.w3.org/2000/svg"
  				className="icon icon-tabler icon-tabler-pencil-plus"
  				width={16}
  				height={16}
  				viewBox="0 0 24 24"
  				strokeWidth={2}
  				stroke="currentColor"
  				fill="none"
  				strokeLinecap="round"
  				strokeLinejoin="round"
				>
				 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
  <path d="M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
  <path d="M14 12l6 0" />
  <path d="M14 16l6 0" />
  <path d="M14 20l6 0" />
				</svg>)


  const addLogoutIcon=(<svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-pencil-plus"
          width={16}
          height={16}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M7 6a7.75 7.75 0 1 0 10 0"></path>
   <path d="M12 4l0 8"></path>
</svg>)

  const addManageIcon=(<svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-pencil-plus"
          width={16}
          height={16}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M6 9l6 0"></path>
   <path d="M4 5l4 0"></path>
   <path d="M6 5v11a1 1 0 0 0 1 1h5"></path>
   <path d="M12 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z"></path>
   <path d="M12 15m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z"></path>
</svg>)

  const addExportsIcon=(<svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-pencil-plus"
          width={16}
          height={16}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v5m-5 6h7m-3 -3l3 3l-3 3" />
</svg>)

	const settingIcon=(<svg
  				xmlns="http://www.w3.org/2000/svg"
  				className="icon icon-tabler icon-tabler-pencil-plus"
  				width={16}
  				height={16}
  				viewBox="0 0 24 24"
  				strokeWidth={2}
  				stroke="currentColor"
  				fill="none"
  				strokeLinecap="round"
  				strokeLinejoin="round"
				>
				 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
  <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
				</svg>)


  const productIcon=(<svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-list-check"
          width={16}
          height={16}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />
    <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />
    <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />
    <path d="M11 6l9 0" />
    <path d="M11 12l9 0" />
    <path d="M11 18l9 0" />
  </svg>)



  const menuItems = [
    {
      title: 'Add user',
      subItems: ['Create users',"Blocked users","View users"],
      icon:addUserIcon,
      path:["/adduser","/blockeduser","/viewuser"]
    },
    {
      title: 'Manage Template',
      subItems: ['Create template'],
      icon:addTemplateIcon,
      path:["/createtemplate"]
    },{
      title: 'Manage Product',
      subItems: ['Assign products'],
      icon:productIcon,
      path:["/products"]
    },
    {
      title: 'Settings',
      subItems: ['Service 1', 'Service 2'],
      icon:settingIcon,
      path:["#","#"]
    },
  ];


	return (
		<div className="w-full bg-gray-100 flex-col">
		{/*{menuItems.map((menuItem, index) => (
        <MenuItem
          key={index}
          title={menuItem.title}
          subItems={menuItem.subItems}
          icon={menuItem.icon}
          path={menuItem.path}
        />
      ))}*/}	
      <Link to="/adduser" className="flex space-x-2 items-center">
      {addUserIcon}
      <p>Add user</p>
      </Link>		
      <Link to="/viewuser" className="flex space-x-2 items-center mt-2">
      {addManageIcon}
      <p>Manage user</p>
      </Link>   
      <Link to="/export" className="flex space-x-2 items-center mt-2">
      {addExportsIcon}
      <p>Exports</p>
      </Link>  
      
      <Link to="/logout" className="flex space-x-2 items-center mt-2">
      {addLogoutIcon}
      <p>Logout</p>
      </Link>   
		</div>
	)
}