import React,{useEffect,useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from "axios";
import FirstTemplate from "../template/FirstTemplate"
import SecondTemplate from "../template/SecondTemplate"
import ErrorTemplate from "../template/ErrorTemplate"

export default function Customer() {
	const [userSlug,setUserSlug] = useState([]);
	const {slug} = useParams()
	const getCustomer=async()=>{
		const data = {slug}
		// const res = await axios.post("http://api.themini.site/api/getwebsite",data)
		const res = await axios.post("http://api.themini.site/api/getwebsite",data)
		if(res.status===200){
			setUserSlug(res.data);
		}
	}

	useEffect(()=>{
		getCustomer();
	},[])

	console.log(userSlug);
	return (
		<div>
			{userSlug[0]?.website?.chooseTemplate==="one"?<FirstTemplate data={userSlug[0]}/>:
			 userSlug[0]?.website?.chooseTemplate==="two"?<SecondTemplate data={userSlug[0]}/>:
			 userSlug[0]?.website?.chooseTemplate==="three"?<SecondTemplate data={userSlug[0]}/>:
			 <div className="w-full h-screen flex justify-center items-center">Loading...</div>}
			
		</div>
	)
}