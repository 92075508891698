import {LOGIN_STATUS} from "./Status"
import axios from "axios";


export const LoginAction=(data)=>{
	return async(dispatch)=>{
		dispatch({type:LOGIN_STATUS.LOGIN_REQUEST})

		const res = await axios.post(`http://api.themini.site/api/login`,data);  
		if(res.status===200){
			const {token,user} = res.data; 
			
			dispatch({
				type:LOGIN_STATUS.LOGIN_SUCCESS,
				payload:{
					token,user
				}
			})

		}else{
		if(res.status===201){
			const err = res.data
			dispatch({
				type:LOGIN_STATUS.LOGIN_FAILED,
				payload:{
					err
				}
			})
		}
	    }
	}
}