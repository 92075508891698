import React,{useEffect,useState,useRef} from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import slug from "slugify";
import Header from "../header/Header"
import Sidebar from "../sidebar/Sidebar" 

import { ToggleSlider }  from "react-toggle-slider";
import { DownloadTableExcel } from 'react-export-table-to-excel';

export default function ViewUser() {
  const [allUsers,setAllUsers] = useState([]);
  const [searchTerm,setSearchTerm] = useState("");
  const [cityTerm,setCityTerm] = useState("");
  const [numberTerm,setNumberTerm] = useState("");
  const [loading,setLoading] = useState(false)
  const [active, setActive] = useState(false);
  const tableRef = useRef(null);


const statusUpdate=async(props)=>{
   const data ={userId:props?.userId,status:props?.status}
   const res = await axios.post("http://api.themini.site/api/updatestatus",data)
   console.log("======>",res.data)
   if(res.status===200){
    setActive(pre=>!pre)
    alert("Status update");

   }

}
	const getCustomers=async()=>{
    setLoading(true)
		// const res = await axios.post("http://api.themini.site/api/getcustomer")
    const res = await axios.post("http://api.themini.site/api/getcustomer")
		if(res.status===200){ 
      setAllUsers(res.data);
      setLoading(false)
		}
	}

  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPage = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPage - postsPerPage;
  const currentPosts = allUsers.slice(indexOfFirstPage, indexOfLastPage);

    const showPagination = () => {
    const pageNumbers = [];
    const totalPosts = allUsers.length;

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav className="flex">
        <ul className="flex space-x-2 p-5">
          {pageNumbers.map(number => (
            <li key={number} className={currentPage === number ? 'page-item active bg-sky-500 text-white px-2' : 'page-item'}>
              <button onClick={() => setCurrentPage(number)} className="page-link flex">
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };




const handleSearch=(e)=>{
  setSearchTerm(e.target.value)
}

const handleCity=(e)=>{
  setCityTerm(e.target.value)
}

const handleNumber=(e)=>{
  setNumberTerm(e.target.value)
}

console.log("All users",allUsers)

const allUsersList=(arr)=>{ 
return arr?.filter(usr=>usr?.name?.toLowerCase()?.includes(searchTerm))?.map(usr=>{
  console.log(usr)
  return(
     <tr className="hover:bg-gray-50">
          <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
             
            <div className="text-sm">
              <div className="font-medium text-gray-700">{usr?.name}</div>
              <div className="text-gray-400">{usr?.email}</div>
            </div>
          </th>
          <td className="px-6 py-4">{usr?.phoneNumber}</td>
          <td className="px-6 py-4">
             {usr?.cityName}
          </td>

        <td className="px-6 py-4">
            <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
              <button className=" rounded-full" >
              {usr?.webData[0]?.status}
              </button>
            </span>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-end gap-4">
            {usr?.webData[0]?.status==="active"?<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={false}/>:<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={true} barBackgroundColorActive="red"/>}
          
             <a x-data="{ tooltip: 'Edite' }" href={usr?.webData[0]?.websiteName}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                  x-tooltip="tooltip"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M8 4h-2l-3 10v2.5" />
  <path d="M16 4h2l3 10v2.5" />
  <path d="M10 16l4 0" />
  <path d="M17.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
  <path d="M6.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
</svg>
              </a>
            </div>
          </td>
        </tr>)
})
}

const renderUsersList=(arr)=>{ 
return arr?.filter(usr=>usr?.name?.toLowerCase()?.includes(searchTerm))?.map(usr=>{
  console.log(usr)
  return(
     <tr className="hover:bg-gray-50">
          <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
             
            <div className="text-sm">
              <div className="font-medium text-gray-700">{usr?.name}</div>
              <div className="text-gray-400">{usr?.email}</div>
            </div>
          </th>
          <td className="px-6 py-4">{usr?.phoneNumber}</td>
          <td className="px-6 py-4">
             {usr?.cityName}
          </td>

        <td className="px-6 py-4">
            <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
              <button className=" rounded-full" >
              {usr?.webData[0]?.status}
              </button>
            </span>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-end gap-4">
            {usr?.webData[0]?.status==="active"?<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={false}/>:<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={true} barBackgroundColorActive="red"/>}
          
             <a x-data="{ tooltip: 'Edite' }" href={usr?.webData[0]?.websiteName}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                  x-tooltip="tooltip"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M8 4h-2l-3 10v2.5" />
  <path d="M16 4h2l3 10v2.5" />
  <path d="M10 16l4 0" />
  <path d="M17.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
  <path d="M6.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
</svg>
              </a>
            </div>
          </td>
        </tr>)
})
}



const renderNumberList=(arr)=>{ 
return arr?.filter(usr=>usr?.phoneNumber?.toLowerCase()?.includes(numberTerm))?.map(usr=>{
  console.log(usr)
return(
    <tr className="hover:bg-gray-50">
          <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
             
            <div className="text-sm">
              <div className="font-medium text-gray-700">{usr?.name}</div>
              <div className="text-gray-400">{usr?.email}</div>
            </div>
          </th>
          <td className="px-6 py-4">{usr?.phoneNumber}</td>
          <td className="px-6 py-4">
             {usr?.cityName}
          </td>

        <td className="px-6 py-4">
            <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
              <button className=" rounded-full" >
              {usr?.webData[0]?.status}
              </button>
            </span>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-end gap-4">
            {usr?.webData[0]?.status==="active"?<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={false}/>:<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={true} barBackgroundColorActive="red"/>}
          
             <a x-data="{ tooltip: 'Edite' }" href={usr?.webData[0]?.websiteName}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                  x-tooltip="tooltip"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M8 4h-2l-3 10v2.5" />
  <path d="M16 4h2l3 10v2.5" />
  <path d="M10 16l4 0" />
  <path d="M17.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
  <path d="M6.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
</svg>
              </a>
            </div>
          </td>
        </tr>)
})
}


const renderCityList=(arr)=>{ 
return arr?.filter(usr=>usr?.cityName?.toLowerCase()?.includes(cityTerm))?.map(usr=>{
  console.log(usr)
  return(
    <tr className="hover:bg-gray-50">
          <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
             
            <div className="text-sm">
              <div className="font-medium text-gray-700">{usr?.name}</div>
              <div className="text-gray-400">{usr?.email}</div>
            </div>
          </th>
          <td className="px-6 py-4">{usr?.phoneNumber}</td>
          <td className="px-6 py-4">
             {usr?.cityName}
          </td>

        <td className="px-6 py-4">
            <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
              <button className=" rounded-full" >
              {usr?.webData[0]?.status}
              </button>
            </span>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-end gap-4">
            {usr?.webData[0]?.status==="active"?<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={false}/>:<ToggleSlider onToggle={state => statusUpdate(usr?.webData[0])} active={true} barBackgroundColorActive="red"/>}
          
             <a x-data="{ tooltip: 'Edite' }" href={usr?.webData[0]?.websiteName}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                  x-tooltip="tooltip"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M8 4h-2l-3 10v2.5" />
  <path d="M16 4h2l3 10v2.5" />
  <path d="M10 16l4 0" />
  <path d="M17.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
  <path d="M6.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0" />
</svg>
              </a>
            </div>
          </td>
        </tr>)
})
}
console.log(allUsers)



  useEffect(()=>{
    getCustomers();
  },[active])

	const preview=(url)=>{
		return(<div className="flex-row justify-center items-center">
			 	<svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-template w-full"
  width={100}
  height={100}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="currentColor"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
  <path d="M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
  <path d="M14 12l6 0" />
  <path d="M14 16l6 0" />
  <path d="M14 20l6 0" />
</svg>
<p className="flex justify-center">
<Link to={"/tmp/"+url+"/"} target="_blank" className="bg-blue-500 text-white py-2 px-5 text-sm rounded-md shadow-md">
Click to Show Preview
</Link>
</p>
    			</div>)
	}
  
  const checked = <svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-photo-check"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="#ffffff"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
  <path d="M9 12l2 2l4 -4" />
</svg>

	return (
		<div>
		<Header/>
		<div className="flex w-9/12 mx-auto space-x-2 h-96">
		<div className="w-2/12 h-96 bg-gray-100 py-2">
		<Sidebar/>
		</div>
		<div className="w-10/12 p-2">
     
		<div className="w-full bg-white border border-[#e9ebee]  rounded-md">
		<div className="flex py-2 px-4  justify-between items-center w-full bg-[#333]">
		<h1 className=" text-md  text-white shadow-sm">Customer Details</h1>
		
		<div className="flex justify-between space-x-2">
   
		{/*<button className="bg-black text-white border px-4 py-1 border-gray-300 hover:bg-white hover:text-black">Exports</button>*/}
		{/*<Link to="/adduser" className="bg-black text-white border px-4 py-1 border-gray-300 hover:bg-white hover:text-black">Add Customers</Link>*/}
    <input type="text" className="px-2  py-2 rounded-sm text-sm" placeholder="Search by Name" value={searchTerm} onChange={handleSearch}/>
    <input type="text" className="px-2  py-2 rounded-sm text-sm" placeholder="Search by City" value={cityTerm} onChange={handleCity}/>
     <input type="text" className="px-2 py-2 rounded-sm text-sm" placeholder="Search by Number" value={numberTerm} onChange={handleNumber}/>
		</div>
		
		</div>

  
    <table className="w-full border-collapse bg-white text-left text-sm text-gray-500" ref={tableRef}>
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-4 font-medium text-gray-900">
            Name
          </th>
          <th scope="col" className="px-6 py-4 font-medium text-gray-900">
            Contact
          </th>
          <th scope="col" className="px-6 py-4 font-medium text-gray-900">
           City
          </th>
          <th scope="col" className="px-6 py-4 font-medium text-gray-900">
            Status
          </th>
          <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-right">
            Action
          </th>
          <th scope="col" className="py-4 font-medium text-gray-900" />
        </tr>
      </thead>





      <tbody className="divide-y divide-gray-100 border-t border-gray-100">
       {searchTerm?.length>0?
       renderUsersList(allUsers):
       numberTerm?.length>0?
       renderNumberList(allUsers):
       cityTerm?.length>0?
       renderCityList(allUsers):allUsersList(currentPosts)}


      </tbody>
    </table> 
    <div className="flex justify-end">{showPagination()}</div>
 

		 
			</div>
		</div>
			 
		</div>
		</div>
	)
}