import React from 'react';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createRoot } from "react-dom/client";
import {Provider} from "react-redux";
import Store from './store'
import {BrowserRouter as Router} from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store";

window.store = Store;
const root = createRoot(document.getElementById("root"));
 
root.render(
  <Provider store={Store}>
  <Router>
  <React.StrictMode>
  <PersistGate persistor={persistor}> 
    <App /> 
  </PersistGate>
  </React.StrictMode>
  </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
