import React from 'react'
import { Link } from 'react-router-dom'
import {useSelector} from "react-redux"
export default function Header() {
		const userDetails = useSelector((state)=>state.auth)
	return (
		<div className="bg-purple-700 w-full py-2 text-white">
			<header className="flex mx-auto justify-between items-center w-9/12">
				<Link to="/home">Admin Panel </Link>
				<p className="text-sm capitalize">Welcome, {userDetails?.user?.fname}</p>
			</header>
		</div>
	)
}