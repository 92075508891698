import React from 'react'

export default function CreateNew() {
	return (
		<div className="w-full bg-white border border-[#e9ebee]  rounded-md">
		<h1 className=" py-4 px-4 text-md">Dash Board</h1>
 
			
		</div>
	)
}