import React,{useState} from 'react'
import { Link,useNavigate} from 'react-router-dom'
import axios from "axios";
import slug from "slugify";

export default function CreateNewUser() {
	const navigate = useNavigate("");
	const [chooseTemplate,setChooseTemplate] = useState("")
	const [name,setName] = useState("")
	const [email,setEmail] = useState("")
	const [natureOfBusiness,setNatureOfBusiness] = useState("")
	const [phoneNumber,setPhoneNumber] = useState("")
	const [cityName,setCityName] = useState("")
	const [address,setAddress] = useState("")
	const [description,setDescription] = useState("")

	const create=async()=>{
		const data = {chooseTemplate, name, email, natureOfBusiness, phoneNumber, cityName, address, description}
		const res = await axios.post("http://api.themini.site/api/customer",data)
		if(res.status===200){
			navigate(`/template/${res?.data?._id}`);
		}
	}






	const checked = <svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-photo-check"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="#ffffff"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
  <path d="M9 12l2 2l4 -4" />
</svg>

	return (
		<div className="w-full bg-white border border-[#e9ebee]  rounded-md">
		<h1 className=" py-4 px-4 text-md bg-[#333] text-white shadow-sm border-b border-gray-200">Add New Customer Details</h1>
		<div>
		<div className="flex space-x-2">
			<div className="flex-col py-2 space-y-2 px-4 w-full border-r border-gray-100">
			
			

			<div>
			<p className="text-md py-1">Name</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full" onChange={(e)=>setName(e.target.value)}/>
	 
			</div>


			<div>
			<p className="text-md py-1">Email address</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full" placeholder="example@mail.com" onChange={(e)=>setEmail(e.target.value)}/> 
			</div>

			<div>
			<p className="text-md py-1">Nature of business</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full"  onChange={(e)=>setNatureOfBusiness(e.target.value)}/> 
			</div>

			<div>
			<p className="text-md py-1">Phone number</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full" placeholder="+91" onChange={(e)=>setPhoneNumber(e.target.value)}/> 
			</div>


			<div>
			<p className="text-md py-1">City name</p>
			<input type="text" className="border border-gray-300 rounded-md py-1 px-2 w-full" onChange={(e)=>setCityName(e.target.value)}/> 
			</div>


			<div>
			<p className="text-md py-1">Address</p>
			<textarea className="border border-gray-300 rounded-md py-1 px-2 h-32 w-full" onChange={(e)=>setAddress(e.target.value)}/>
			</div>
{/*
			<div>
			<p className="text-md py-1">Page description</p>
			<textarea className="border border-gray-300 rounded-md py-1 px-2 h-32 w-full" onChange={(e)=>setDescription(e.target.value)}/>
			</div>*/}
			{/*<div>
			<p className="text-md py-1">Choose Banner</p>
			<input type="file" placeholder="Customer name" className="border border-gray-300 rounded-md py-1 px-2 w-full"/>
			</div>*/}
			<div>
			<button  className="bg-green-600 text-white border border-gray-300 rounded-md py-1 px-2 w-full" onClick={(e)=>create()}>Next</button></div>
			{/*<Link to="/template/123">Next</Link>*/}
			
			</div>
			<div className="flex flex-col w-full justify-center items-center">
			<svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-browser-check"
  width={100}
  height={100}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="gray"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
  <path d="M4 8h16" />
  <path d="M8 4v4" />
  <path d="M9.5 14.5l1.5 1.5l3 -3" />
</svg>

				<h1>Web Preview</h1>
			</div>
			</div>
		</div>
			
		</div>
	)
}