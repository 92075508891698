import React from 'react'
import Header from "../header/Header"
import Sidebar from "../sidebar/Sidebar"
import CreateNew from "../content/CreateNew"
import {useSelector} from "react-redux"
export default function Home() {

	const userDetails = useSelector((state)=>state.auth)

	return (
		<div>
		<Header/>
		<div className="flex w-9/12 mx-auto space-x-2 h-96">
		<div className="w-2/12 h-96 bg-gray-100 py-2">
		<Sidebar/>
		</div>
		<div className="w-10/12 p-2">
			
			<CreateNew/>
		</div>
		</div>
			
		</div>
	)
}