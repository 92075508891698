import React,{useState} from 'react'
import axios from "axios";
import Green from "./Green.jpg"
import Pink from "./Pink.jpg"
export default function FirstTemplate(props) {
  console.log(props?.data)
 const allData = props?.data

  return (
   <div style={{ 
     backgroundImage: `url(${Pink})`,
      
      width:"100%",
      backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
       
        width: '100vw',
        height: 'auto'
    }} >
    
    <header className="flex-col bg-pink-500 bg-opacity-80 py-5">
      <div className="flex container mx-auto justify-center items-center text-white  px-2 text-4xl w-9/12 capitalize"><h1>{allData?.websiteName}</h1></div>
      <div className="flex container mx-auto justify-between space-x-5 items-center text-gray-100 shadow-sm  px-2 w-9/12">
    
      {allData?.headerDetails?.map(hd=>{
        return(
          <h1 className="capitalize">{hd?.headerName}</h1>
          )
      })}
      </div>
    </header>
    <div className="">
    <div className="bg-pink-300 bg-opacity-80 mt-10 flex-col mx-auto container min-h-[500px] w-9/12 rounded-md p-2">
      <div className="flex flex-wrap grid grid-cols-3 gap-4">


 {allData?.product?.map(dt=>{
        return(
      <div className="w-full bg-white p-2 mb-5 sm:mb-0">
        <div className="border-b flex justify-center font-[300] font-[arial,sans-serif] border-gray-300">{dt?.title}</div>
        <div className="flex-col">
        {dt?.inputs?.map(inp=>{
          return(
          <div className="flex border-b border-gray-200 justify-between text-gray-700">
            <p>{inp?.name}</p>
            <p>${inp?.price}</p>
          </div>)
        })}

        </div>
      </div>
      )
      })}
       


      </div>
      </div>
      </div>

      <footer className="mt-5 h-60 w-full bg-gray-300">
        <>
  {/* component */}
  <footer className="bg-white dark:bg-gray-900">
    <div className="container px-6 py-12 mx-auto">
      <div className="text-center">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
          Let’s get started on something great
        </h2>
        <p className="max-w-md mx-auto mt-2 text-gray-500 dark:text-gray-400">
          Join over 4,000+ startups already growing with Meraki UI.
        </p>
        <div className="flex flex-col mt-6 sm:flex-row sm:items-center sm:justify-center">
          <button className="w-full px-5 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
            Get started
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Product
          </h3>
          <div className="flex flex-col items-start mt-4 space-y-4">
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Overview
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Features
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Solutions
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Tutorials
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Pricing
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Releases
            </a>
          </div>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Company
          </h3>
          <div className="flex flex-col items-start mt-4 space-y-4">
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              About us
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Careers
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Press
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              News
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Media kit
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Contact
            </a>
          </div>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Resources
          </h3>
          <div className="flex flex-col items-start mt-4 space-y-4">
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Blog
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Newsletter
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Events
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Help center
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Tutorials
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Supports
            </a>
          </div>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Use cases
          </h3>
          <div className="flex flex-col items-start mt-4 space-y-4">
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Startups
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Enterprise
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Government
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Saas
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Marketplaces
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Ecommerce
            </a>
          </div>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Social
          </h3>
          <div className="flex flex-col items-start mt-4 space-y-4">
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Twitter
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              LinkedIn
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Github
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Facebook
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              AngelList
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Dribble
            </a>
          </div>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Legal
          </h3>
          <div className="flex flex-col items-start mt-4 space-y-4">
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Terms
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Privacy
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Cookies
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Licenses
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Settings
            </a>
            <a
              href="#"
              className="text-gray-700 transition-colors duration-200 dark:text-gray-200 dark:hover:text-blue-400 hover:underline hover:text-blue-600"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 md:my-10 dark:border-gray-700" />
      <div className="flex flex-col items-center justify-between sm:flex-row">
        <a href="#">
          <img
            className="w-auto h-7"
            src="https://merakiui.com/images/full-logo.svg"
            alt=""
          />
        </a>
        <p className="mt-4 text-sm text-gray-500 sm:mt-0 dark:text-gray-300">
          © Copyright 2023. All Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
</>

      </footer>
   
  </div>

  )
}